// @ts-ignore
import ApiService from "@/common/api.service";
// @ts-ignore
import { GetterTree, ActionTree, MutationTree, Module } from 'vuex';
import API from "@/common/API"

/* eslint-disable @typescript-eslint/no-explicit-any */

// action types

export const MEDIA_PRESS = "listMediaPress"
export const DETAIL_MEDIA_PRESS = "detailMediaPress"
export const DELETE_MEDIA_PRESS = "deleteMediaPress"
export const CREATE_MEDIA_PRESS = "createMediaPress"
export const UPDATE_MEDIA_PRESS = "updateMediaPress"

// mutation types
export const SET_MEDIA_PRESS = "detail_media_press"
export const SET_ERROR = "setError"

export const state = () => ({
    media_press: {},
    list_media_press: [] as any,
})

type RootState = ReturnType<typeof state>
const getters: GetterTree<RootState, RootState> = {
    detail_media_press: state => state.media_press,
    listMediaPress: state => state.list_media_press,
}

const mutations: MutationTree<RootState> = {
    [SET_MEDIA_PRESS](state, data) {
        state.media_press = data;
    },
    [MEDIA_PRESS](state, data) {
        state.list_media_press = data;
    },
}

const actions: ActionTree<RootState, RootState> = {
 
    [MEDIA_PRESS]({ commit }, credentials) {
        return new Promise((resolve, reject) => {
            ApiService.query(`${API.MEDIA_PRESS}`, credentials).then((data: any) => {
                if (data) {
                    commit(MEDIA_PRESS, data.data.data)
                }
                resolve(data);
            })
                .catch(({ response }) => {
                    reject(response);
                });
        })
    },
    [DETAIL_MEDIA_PRESS](context, credentials) {
        return new Promise((resolve, reject) => {
            ApiService.get(API.MEDIA_PRESS, credentials)
                .then((data: any) => {
                    if (data.status) {
                        context.commit(SET_MEDIA_PRESS, data.data.data);
                    } else {
                        context.commit(SET_ERROR, data.message);
                    }                   
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [CREATE_MEDIA_PRESS](context, credentials) {
        return new Promise((resolve, reject) => {
            ApiService.post(API.CREATE_MEDIA_PRESS, credentials)
                .then((data) => {
                    resolve(data)
                }).catch((err) => {
                    reject(err.response.data);
                });
        });
    },
    [UPDATE_MEDIA_PRESS](context, credentials) {
        return new Promise((resolve, reject) => {
            ApiService.put(`${API.UPDATE_MEDIA_PRESS}/${credentials.uuid}`, credentials)
                .then((data) => {
                    resolve(data)
                }).catch((err) => {
                    reject(err.response.data);
                });
        });
    },

    [DELETE_MEDIA_PRESS](context, credentials) {
        return new Promise((resolve, reject) => {
            ApiService.delete(API.DELETE_MEDIA_PRESS, credentials)
                .then((data: any) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
}
const mediaPressModule: Module<any, any> = {
    state,
    mutations,
    actions,
    getters,
}

export default mediaPressModule