// @ts-ignore
import ApiService from "@/common/api.service";
// @ts-ignore
import { GetterTree, ActionTree, MutationTree, Module } from 'vuex';
import API from "@/common/API"

/* eslint-disable @typescript-eslint/no-explicit-any */

// action types

export const MENU_FOOTER = "listMenuFooter"
export const DETAIL_MENU_FOOTER = "detailMenuFooter"
export const DELETE_MENU_FOOTER = "deleteMenuFooter"
export const CREATE_MENU_FOOTER = "createMenuFooter"
export const UPDATE_MENU_FOOTER = "updateMenuFooter"

// mutation types
export const SET_MENU_FOOTER = "detail_menu_footer"
export const SET_ERROR = "setError"

export const state = () => ({
    menu_footer: {},
    list_menu_footer: [] as any,
})

type RootState = ReturnType<typeof state>
const getters: GetterTree<RootState, RootState> = {
    detail_menu_footer: state => state.menu_footer,
    listMenuFooter: state => state.list_menu_footer,
}

const mutations: MutationTree<RootState> = {
    [SET_MENU_FOOTER](state, data) {
        state.menu_footer = data;
    },
    [MENU_FOOTER](state, data) {
        state.list_menu_footer = data;
    },
}

const actions: ActionTree<RootState, RootState> = {
 
    [MENU_FOOTER]({ commit }, credentials) {
        return new Promise((resolve, reject) => {
            ApiService.query(`${API.MENU_FOOTER}`, credentials).then((data: any) => {
                if (data) {
                    commit(MENU_FOOTER, data.data.data)
                }
                resolve(data);
            })
                .catch(({ response }) => {
                    reject(response);
                });
        })
    },
    [DETAIL_MENU_FOOTER](context, credentials) {
        return new Promise((resolve, reject) => {
            ApiService.get(API.MENU_FOOTER, credentials)
                .then((data: any) => {
                    if (data.status) {
                        context.commit(SET_MENU_FOOTER, data.data.data);
                    } else {
                        context.commit(SET_ERROR, data.message);
                    }                   
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [CREATE_MENU_FOOTER](context, credentials) {
        return new Promise((resolve, reject) => {
            ApiService.post(API.CREATE_MENU_FOOTER, credentials)
                .then((data) => {
                    resolve(data)
                }).catch((err) => {
                    reject(err.response.data);
                });
        });
    },
    [UPDATE_MENU_FOOTER](context, credentials) {
        return new Promise((resolve, reject) => {
            ApiService.put(`${API.UPDATE_MENU_FOOTER}/${credentials.uuid}`, credentials)
                .then((data) => {
                    resolve(data)
                }).catch((err) => {
                    reject(err.response.data);
                });
        });
    },

    [DELETE_MENU_FOOTER](context, credentials) {
        return new Promise((resolve, reject) => {
            ApiService.delete(API.DELETE_MENU_FOOTER, credentials)
                .then((data: any) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
}
const menuFooterModule: Module<any, any> = {
    state,
    mutations,
    actions,
    getters,
}

export default menuFooterModule