// @ts-ignore
import ApiService from "@/common/api.service";
// @ts-ignore
import { GetterTree, ActionTree, MutationTree, Module } from 'vuex';
import API from "@/common/API"

/* eslint-disable @typescript-eslint/no-explicit-any */

// action types

export const ADVISER = "listAdviser"
export const DETAIL_ADVISER = "detailAdviser"
export const DELETE_ADVISER = "deleteAdviser"
export const CREATE_ADVISER = "createAdviser"
export const UPDATE_ADVISER = "updateAdviser"

// mutation types
export const SET_ADVISER = "detail_adviser"
export const SET_ERROR = "setError"

export const state = () => ({
    adviser: {},
    list_adviser: [] as any,
})

type RootState = ReturnType<typeof state>
const getters: GetterTree<RootState, RootState> = {
    detail_adviser: state => state.adviser,
    listAdviser: state => state.list_adviser,
}

const mutations: MutationTree<RootState> = {
    [SET_ADVISER](state, data) {
        state.adviser = data;
    },
    [ADVISER](state, data) {
        state.list_adviser = data;
    },
}

const actions: ActionTree<RootState, RootState> = {
 
    [ADVISER]({ commit }, credentials) {
        return new Promise((resolve, reject) => {
            ApiService.query(`${API.ADVISER}`, credentials).then((data: any) => {
                if (data) {
                    commit(ADVISER, data.data.data)
                }
                resolve(data);
            })
                .catch(({ response }) => {
                    reject(response);
                });
        })
    },
    [DETAIL_ADVISER](context, credentials) {
        return new Promise((resolve, reject) => {
            ApiService.get(API.ADVISER, credentials)
                .then((data: any) => {
                    if (data.status) {
                        context.commit(SET_ADVISER, data.data.data);
                    } else {
                        context.commit(SET_ERROR, data.message);
                    }                   
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [CREATE_ADVISER](context, credentials) {
        return new Promise((resolve, reject) => {
            ApiService.post(API.CREATE_ADVISER, credentials)
                .then((data) => {
                    resolve(data)
                }).catch((err) => {
                    reject(err.response.data);
                });
        });
    },
    [UPDATE_ADVISER](context, credentials) {
        return new Promise((resolve, reject) => {
            ApiService.put(`${API.UPDATE_ADVISER}/${credentials.uuid}`, credentials)
                .then((data) => {
                    resolve(data)
                }).catch((err) => {
                    reject(err.response.data);
                });
        });
    },

    [DELETE_ADVISER](context, credentials) {
        return new Promise((resolve, reject) => {
            ApiService.delete(API.DELETE_ADVISER, credentials)
                .then((data: any) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
}
const adviserModule: Module<any, any> = {
    state,
    mutations,
    actions,
    getters,
}

export default adviserModule