// @ts-ignore
import ApiService from "@/common/api.service";
// @ts-ignore
import { GetterTree, ActionTree, MutationTree, Module } from 'vuex';
import API from "@/common/API"

/* eslint-disable @typescript-eslint/no-explicit-any */

// action types

export const DISPLAY_ADS = "listDisplayAds"
export const DETAIL_DISPLAY_ADS = "detailDisplayAds"
export const DELETE_DISPLAY_ADS = "deleteDisplayAds"
export const CREATE_DISPLAY_ADS = "createDisplayAds"
export const UPDATE_DISPLAY_ADS = "updateDisplayAds"

// mutation types
export const SET_DISPLAY_ADS = "detail_display_ads"
export const SET_ERROR = "setError"

export const state = () => ({
    display_ads: {},
    list_display_ads: [] as any,
})

type RootState = ReturnType<typeof state>
const getters: GetterTree<RootState, RootState> = {
    detail_display_ads: state => state.display_ads,
    listDisplayAds: state => state.list_display_ads,
}

const mutations: MutationTree<RootState> = {
    [SET_DISPLAY_ADS](state, data) {
        state.display_ads = data;
    },
    [DISPLAY_ADS](state, data) {
        state.list_display_ads = data;
    },
}

const actions: ActionTree<RootState, RootState> = {
 
    [DISPLAY_ADS]({ commit }, credentials) {
        return new Promise((resolve, reject) => {
            ApiService.query(`${API.DISPLAY_ADS}`, credentials).then((data: any) => {
                if (data) {
                    commit(DISPLAY_ADS, data.data.data)
                }
                resolve(data);
            })
                .catch(({ response }) => {
                    reject(response);
                });
        })
    },
    [DETAIL_DISPLAY_ADS](context, credentials) {
        return new Promise((resolve, reject) => {
            ApiService.get(API.DISPLAY_ADS, credentials)
                .then((data: any) => {
                    if (data.status) {
                        context.commit(SET_DISPLAY_ADS, data.data.data);
                    } else {
                        context.commit(SET_ERROR, data.message);
                    }                   
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [CREATE_DISPLAY_ADS](context, credentials) {
        return new Promise((resolve, reject) => {
            ApiService.post(API.CREATE_DISPLAY_ADS, credentials)
                .then((data) => {
                    resolve(data)
                }).catch((err) => {
                    reject(err.response.data);
                });
        });
    },
    [UPDATE_DISPLAY_ADS](context, credentials) {
        return new Promise((resolve, reject) => {
            ApiService.put(`${API.UPDATE_DISPLAY_ADS}/${credentials.uuid}`, credentials)
                .then((data) => {
                    resolve(data)
                }).catch((err) => {
                    reject(err.response.data);
                });
        });
    },

    [DELETE_DISPLAY_ADS](context, credentials) {
        return new Promise((resolve, reject) => {
            ApiService.delete(API.DELETE_DISPLAY_ADS, credentials)
                .then((data: any) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
}
const displayAdsModule: Module<any, any> = {
    state,
    mutations,
    actions,
    getters,
}

export default displayAdsModule