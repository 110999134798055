// @ts-ignore
import ApiService from "@/common/api.service";
// @ts-ignore
import { GetterTree, ActionTree, MutationTree, Module } from 'vuex';
import API from "@/common/API"

/* eslint-disable @typescript-eslint/no-explicit-any */

// action types

export const CATEGORY_NEWSES = "listCategoryNewses"
export const DETAIL_CATEGORY_NEWSES = "dtailCategoryNewses"
export const DELETE_CATEGORY_NEWSES = "deleteCategoryNewses"
export const CREATE_CATEGORY_NEWSES = "createCategoryNewses"
export const UPDATE_CATEGORY_NEWSES = "updateCategoryNewses"
export const LIST_CATEGORY_NEWSES = "categoryNewses"

// mutation types
export const SET_CATEGORY = "category_newses"
export const SET_ALL_CATEGORYNEWSES = "all_category"
export const SET_ERROR = "setError"

export const state = () => ({
    category_newsess: {},
    all_category: {},
    list_category_newses: [] as any,
})

type RootState = ReturnType<typeof state>
const getters: GetterTree<RootState, RootState> = {
    detail_category_newses: state => state.category_newsess,
    listCategoryNewses: state => state.list_category_newses,
    allCategoryNewses:state => state.all_category,
}

const mutations: MutationTree<RootState> = {
    [SET_CATEGORY](state, data) {
        state.category_newsess = data;
    },
    [SET_ALL_CATEGORYNEWSES](state, data) {
        state.all_category = data;
    },
    [CATEGORY_NEWSES](state, data) {
        state.list_category_newses = data;
    },
}

const actions: ActionTree<RootState, RootState> = {
 
    [CATEGORY_NEWSES]({ commit }, credentials) {
        return new Promise((resolve, reject) => {
            ApiService.query(`${API.CATEGORY_NEWSES}`, credentials).then((data: any) => {
                if (data) {
                    commit(CATEGORY_NEWSES, data.data.data)
                }
                resolve(data);
            })
                .catch(({ response }) => {
                    reject(response);
                });
        })
    },
    [LIST_CATEGORY_NEWSES]({ commit }) {
        return new Promise((resolve, reject) => {
            ApiService.query(API.LIST_CATEGORY_NEWSES, '').then((data: any) => {
                if (data) {
                    commit(SET_ALL_CATEGORYNEWSES, data.data.data)
                }
                resolve(data);
            })
                .catch(({ response }) => {
                    reject(response);
                });
        })
    },
    [DETAIL_CATEGORY_NEWSES](context, credentials) {
        return new Promise((resolve, reject) => {
            ApiService.get(API.CATEGORY_NEWSES, credentials)
                .then((data: any) => {
                    if (data.status) {
                        context.commit(SET_CATEGORY, data.data.data);
                    } else {
                        context.commit(SET_ERROR, data.message);
                    }                    
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [CREATE_CATEGORY_NEWSES](context, credentials) {
        return new Promise((resolve, reject) => {
            ApiService.post(API.CREATE_CATEGORY_NEWSES, credentials)
                .then((data) => {
                    resolve(data)
                }).catch((err) => {
                    reject(err.response.data);
                });
        });
    },
    [UPDATE_CATEGORY_NEWSES](context, credentials) {
        return new Promise((resolve, reject) => {
            ApiService.put(`${API.UPDATE_CATEGORY_NEWSES}/${credentials.id}`, credentials)
                .then((data) => {
                    resolve(data)
                }).catch((err) => {
                    reject(err.response.data);
                });
        });
    },

    [DELETE_CATEGORY_NEWSES](context, credentials) {
        return new Promise((resolve, reject) => {
            ApiService.delete(API.DELETE_CATEGORY_NEWSES, credentials)
                .then((data: any) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
}
const categoryNewsesModule: Module<any, any> = {
    state,
    mutations,
    actions,
    getters,
}

export default categoryNewsesModule