import ApiService from "@/common/api.service"
import { GetterTree, ActionTree, MutationTree, Module } from 'vuex'
import API from "@/common/API"

// action types
export const GET_LIST_SCORE_SHEETS = "listScoreSheets"
export const DETAIL_SCORE_SHEET = "detailScoreSheet"
export const DELETE_SCORE_SHEET = "deleteScoreSheet"
export const CREATE_SCORE_SHEET = "createScoreSheet"
export const UPDATE_SCORE_SHEET = "updateScoreSheet"

// mutation types
export const SET_SCORE_SHEET = "scoreSheet"
export const SET_LIST_SCORE_SHEET = "getListScoreSheet"
export const SET_ERROR = "setError"

export const state = () => ({
    scoreSheet: {},
    scoreSheets: [] as any
})

type RootState = ReturnType<typeof state>
const getters: GetterTree<RootState, RootState> = {
    scoreSheet: state => state.scoreSheet,
    scoreSheets: state => state.scoreSheets
}

const mutations: MutationTree<RootState> = {
    [SET_LIST_SCORE_SHEET](state, data) {
        state.scoreSheets = data
    },
    [SET_SCORE_SHEET](state, data) {
        state.scoreSheet = data
    }
}

const actions: ActionTree<RootState, RootState> = {
    [GET_LIST_SCORE_SHEETS]({ commit }, credentials) {
        return new Promise((resolve, reject) => {
            ApiService.query(API.SCORE_SHEETS, credentials).then((data: any) => {
                if (data.data.status) {
                    commit(SET_LIST_SCORE_SHEET, data.data.data)
                }
                resolve(data)
            })
                .catch(({ response }) => {
                    reject(response)
                })
        })
    },
    [DETAIL_SCORE_SHEET](context, credentials) {
        return new Promise((resolve, reject) => {
            ApiService.get(API.SCORE_SHEETS, credentials)
                .then((data: any) => {
                    if (data.status) {
                        context.commit(SET_SCORE_SHEET, data.data.data)
                    } else {
                        context.commit(SET_ERROR, data.message)
                    }
                    resolve(data)
                })
                .catch(({ response }) => {
                    reject(response)
                })
        })
    },
    [CREATE_SCORE_SHEET](context, credentials) {
        return new Promise((resolve, reject) => {
            ApiService.post(API.SCORE_SHEETS, credentials)
                .then((data) => {
                    resolve(data)
                }).catch((err) => {
                    reject(err.response.data)
                })
        })
    },
    [UPDATE_SCORE_SHEET](context, credentials) {
        return new Promise((resolve, reject) => {
            ApiService.put(`${API.SCORE_SHEETS}/${credentials.id}`, credentials)
                .then((data) => {
                    resolve(data)
                }).catch((err) => {
                    reject(err.response.data)
                })
        })
    },
    [DELETE_SCORE_SHEET](context, credentials) {
        return new Promise((resolve, reject) => {
            ApiService.delete(API.SCORE_SHEETS, credentials)
                .then((data: any) => {
                    resolve(data)
                })
                .catch(({ response }) => {
                    reject(response)
                })
        })
    }
}
const scoreSheetModule: Module<any, any> = {
    state,
    mutations,
    actions,
    getters,
}

export default scoreSheetModule