// @ts-ignore
import ApiService from "@/common/api.service";
// @ts-ignore
import { GetterTree, ActionTree, MutationTree, Module } from 'vuex';
import API from "@/common/API"

/* eslint-disable @typescript-eslint/no-explicit-any */

// action types

export const NEWSES = "listNewses"
export const DETAIL_NEWSES = "dtailNewses"
export const DELETE_NEWSES = "deleteNewses"
export const CREATE_NEWSES = "createNewses"
export const UPDATE_NEWSES = "updateNewses"

// mutation types
export const SET_NEWSES = "detail_newses"
export const SET_ERROR = "setError"

export const state = () => ({
    newses: {},
    list_newses: [] as any,
})

type RootState = ReturnType<typeof state>
const getters: GetterTree<RootState, RootState> = {
    detail_newses: state => state.newses,
    listNewses: state => state.list_newses,
}

const mutations: MutationTree<RootState> = {
    [SET_NEWSES](state, data) {
        state.newses = data;
    },
    [NEWSES](state, data) {
        state.list_newses = data;
    },
}

const actions: ActionTree<RootState, RootState> = {
 
    [NEWSES]({ commit }, credentials) {
        return new Promise((resolve, reject) => {
            ApiService.query(`${API.NEWSES}`, credentials).then((data: any) => {
                if (data) {
                    commit(NEWSES, data.data.data)
                }
                resolve(data);
            })
                .catch(({ response }) => {
                    reject(response);
                });
        })
    },
    [DETAIL_NEWSES](context, credentials) {
        return new Promise((resolve, reject) => {
            ApiService.get(API.NEWSES, credentials)
                .then((data: any) => {
                    if (data.status) {
                        context.commit(SET_NEWSES, data.data.data);
                    } else {
                        context.commit(SET_ERROR, data.message);
                    }                   
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    [CREATE_NEWSES](context, credentials) {
        return new Promise((resolve, reject) => {
            ApiService.post(API.CREATE_NEWSES, credentials)
                .then((data) => {
                    resolve(data)
                }).catch((err) => {
                    reject(err.response.data);
                });
        });
    },
    [UPDATE_NEWSES](context, credentials) {
        return new Promise((resolve, reject) => {
            ApiService.put(`${API.UPDATE_NEWSES}/${credentials.id}`, credentials)
                .then((data) => {
                    resolve(data)
                }).catch((err) => {
                    reject(err.response.data);
                });
        });
    },

    [DELETE_NEWSES](context, credentials) {
        return new Promise((resolve, reject) => {
            ApiService.delete(API.DELETE_NEWSES, credentials)
                .then((data: any) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
}
const categoryNewsesModule: Module<any, any> = {
    state,
    mutations,
    actions,
    getters,
}

export default categoryNewsesModule